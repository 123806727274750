export default {
  text: '#000000',
  text2: '#000000',
  primary: '#FFDE59',
  secondary: '#FFDE59',
  background: 'white',
  backgroundPrimary: '#D9D9D9',
  backgroundSecondary: '#D9D9D9',
  backgroundTertiary: '#D9D9D9',
  light: '#f7f7f7',
  dark: '#2B2219',
};
