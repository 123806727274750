export default {
  heading: 'Didot Bold, sans-serif',
  subheading: 'Gotham, sans-serif',
  body: 'Gotham, sans-serif',
  body2: 'Comfortaa, sans-serif',
  monospace: 'Menlo, monospace',
  display: 'Greatday, cursive',
  googleFonts: [
    'Open Sans:100,200,300,400,500,600,700,800,900',
    'Comfortaa',
    'Italiana',
  ],
  customFamilies: ['Greatday', 'Gotham'],
  customUrls: [
    'https://www.gonation.biz/fonts/massimo/greatday.css',
    'https://www.gonation.biz/fonts/massimo/gotham-bold.css',
    // 'https://www.gonation.biz/fonts/corinthian-light/corinthian-light.css',
    // 'https://www.gonation.biz/fonts/futura/futura.css',
  ],
};
